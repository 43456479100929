import LocalFont from 'next/font/local';

const arialFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/arial-regular.woff2',
      weight: 'bold',
      style: 'normal',
    },
  ],
});

const escrowbannerFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/EscrowBannerCompressed-Bold.woff2',
      weight: 'bold',
      style: 'normal',
    },
  ],
});

const escrowFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/Escrow-Bold.woff2',
      weight: '900',
      style: 'normal',
    },
    {
      path: '../../public/next/fonts/Escrow-Semibold.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

export { arialFont, escrowFont, escrowbannerFont };
